import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import logo from '../Images/Logo.png';


export const Navbar = () => {
    const location = useLocation();
    const [activeNav, setActiveNav] = useState(location.pathname);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        setActiveNav(location.pathname);
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const handleNavClick = (path) => {
        setActiveNav(path);
        setIsDropdownOpen(false); // Hide the dropdown menu when a sub-item is clicked
        const navbarCollapse = document.getElementById('navbarCollapse');
        if (navbarCollapse && navbarCollapse.classList.contains('show')) {
            navbarCollapse.classList.remove('show');
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const openWhatsApp = () => {
        const whatsappNumber = '+94772427603';
        const message = encodeURIComponent('Hello, I would like to know more about...');
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${message}`;

        window.open(whatsappUrl, '_blank', 'width=600,height=600');
    };

    const handleClick = () => {
        openWhatsApp();
    };

    return (
        <>
            {/* Navbar & Carousel Start */}
            <div className="container-fluid position-relative p-0">
                <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
                    <Link to="/" className="navbar-brand p-0">
                        <h1 className="m-0"><img src={logo} style={{ height: '80px' }} alt="Logo" /></h1>
                    </Link>
                    <button className="navbar-toggler" type="button" onClick={() => document.getElementById('navbarCollapse').classList.toggle('show')}>
                        <span className="fa fa-bars"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav ms-auto py-0">
                            <Link to="/" className={`nav-item nav-link ${activeNav === '/' ? 'active' : ''}`} onClick={() => handleNavClick('/')}>Home</Link>
                            <Link to="/service" className={`nav-item nav-link ${activeNav === '/service' ? 'active' : ''}`} onClick={() => handleNavClick('/service')}>Services</Link>
                            <Link to="/about" className={`nav-item nav-link ${activeNav === '/about' ? 'active' : ''}`} onClick={() => handleNavClick('/about')}>About</Link>
                            <Link to="/contact" className={`nav-item nav-link ${activeNav === '/contact' ? 'active' : ''}`} onClick={() => handleNavClick('/contact')}>Contact</Link>
                            <div className={`nav-item dropdown ${isDropdownOpen ? 'show' : ''}`}>
                                <Link to="#" className={`nav-link dropdown-toggle ${activeNav.startsWith('/buy') ? 'active' : ''}`} onClick={toggleDropdown}>Buy</Link>
                                <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                                   
                                    <Link to="/buy-project" className={`dropdown-item ${activeNav === '/buy-project' ? 'active' : ''}`} onClick={() => handleNavClick('/buy-project')}>Projects</Link>
                                     {/* <Link to="/buy-course" className={`dropdown-item ${activeNav === '/buy-course' ? 'active' : ''}`} onClick={() => handleNavClick('/buy-course')}>Courses</Link> */}
                                </div>
                            </div>
                        </div>
                        <Link to="/contact" className="btn btn-primary py-2 px-4 ms-3 whatsapp-btn"
                              style={{color: '#000000', textDecoration: 'none', borderColor: '#9202ec'}}
                              onMouseEnter={e => e.currentTarget.style.color = '#ffffff'}
                              onMouseMove={e => e.currentTarget.style.backgroundColor = '#9202ec'}
                              onMouseLeave={e => e.currentTarget.style.borderColor = '#9202ec'}
                              onClick={handleClick}>
                            <i className="fab fa-whatsapp text-white"></i>
                        </Link>
                    </div>
                </nav>
            </div>
            {/* Navbar & Carousel End */}
        </>
    );
};

export default Navbar;
