import { Link } from 'react-router-dom';
import { ServiceSub } from "../component/ServiceSub";
import { Member } from "../component/Member";
import { Hero } from "../component/Hero";
import { Vendor } from "../component/Vendor";
import { Product } from "../component/Product";
import { Pricing } from "../component/Pricing";
import { Quote } from "../component/Quote";
import { Facts } from '../component/Facts';


export const Home = () => {
    return (
        <>
            <div
                id="header-carousel"
                className="carousel slide carousel-fade"
                data-bs-ride="carousel"
            >
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        {/* <img className="w-100" src="img/robot-working-office-instead-humans.jpg" alt="Image"/> */}
                        <Hero />
                        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                            <div className="p-3" style={{ maxWidth: "900px" }}>
                                <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                                    {/* Creative & Innovative */}
                                </h5>
                                <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                                    Creative & Innovative Digital Solution
                                </h1>
                                <Link
                                    to="/quote"
                                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft "
                                    onMouseEnter={e => {
                                        e.currentTarget.style.backgroundColor = '#9202ec';
                                        e.currentTarget.style.borderColor = '#9202ec';
                                        e.currentTarget.style.color = '#FFFFFF';
                                    }}
                                    onMouseLeave={e => {
                                        e.currentTarget.style.backgroundColor = '';
                                        e.currentTarget.style.color = '';
                                    }}
                                >
                                    Free Quote
                                </Link>

                                <Link
                                    to="/contact"
                                    className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                                >
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#header-carousel"
                    data-bs-slide="prev"
                >
          <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
          ></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#header-carousel"
                    data-bs-slide="next"
                >
          <span
              className="carousel-control-next-icon"
              aria-hidden="true"
          ></span>
                    <span className="visually-hidden">Next</span>
                </button>
          
     

            </div>


          

            <div className='mt-5'>
                <Facts />
            </div>
            {/*Vendor Start*/}
     
            <Vendor />
            {/*Vendor End*/}

            {/* Service Start*/}
            <ServiceSub />
            {/*Service End*/}

            {/*Pricing Plan Start*/}
            <Pricing />
            {/*Pricing Plan End*/}

            {/*Quote Start*/}
            <Quote />
            {/*Quote End*/}

            {/* Testimonial Start */}
            {/*Testimonial End*/}

            {/*Team Start*/}
            {/* <Member /> */}
            {/*Team End*/}

            {/* Blog Start*/}
            <Product />
            {/*Blog Start*/}

        </>
    );
};
